import styles from './hero-text.module.css'

export const HeroText = () => (
  <h1 className={styles.heroText}>
    <span>Matilda</span>
    <span className={styles.xs}>och</span>
    <span>Gustafs</span>
    <span>bröllop</span>
  </h1>
)
