import { Link } from 'react-router-dom'
import { App } from '../../components/app'
import { Image } from '../../components/image'
import { HeroText } from '../../components/hero-text'
// @ts-ignore
import photo from '../../assets/images/midsommar.jpeg?sizes[]=768'
import styles from './home.module.css'

export function Home() {
  return (
    <App>
      <HeroText />
      <h2 className={styles.subHeading}>6–7 juni 2024 i Ängelholm</h2>
      <div className={styles.photoWrapper}>
        <Image className={styles.photo} {...photo} alt="Matilda och Gustaf" />
      </div>
      <div>
        <p>
          Vi har valt att gifta oss i Ängelholm, Skåne, en plats som betyder
          mycket för oss båda. Som ni säkert redan vet har Gustaf spenderat tid
          här sedan barnsben och tillsammans har vi spenderat många somrar här.
          Därför känns det extra roligt att få fira kärleken med er just där.
          Bröllopet sker 6-7 juni 2024 men ni väljer såklart själva om ni vill
          vara med hela helgen eller bara bröllopsdagen den 7e juni.
        </p>
        <p>
          Vi ser fram emot att få fira denna glädjefyllda dag tillsammans med
          er!
        </p>
      </div>
      <Link to="/osa" className={styles.cta}>
        OSA senast den 1 april
      </Link>
    </App>
  )
}
