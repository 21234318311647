import { Children, cloneElement } from 'react'
import styles from './marquee.module.css'

export const Marquee = ({ children }: React.PropsWithChildren) => {
  const childrenClone = Children.toArray(children).map((element: any) =>
    typeof element === 'string' || typeof element === 'number' ? (
      <span aria-hidden>{element}</span>
    ) : (
      cloneElement(element, { 'aria-hidden': true })
    ),
  )

  return (
    <div className={styles.marquee}>
      {children}
      {childrenClone}
    </div>
  )
}
