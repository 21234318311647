import { useState } from 'react'
import cn from 'classnames'
import styles from './image.module.css'

type ImageProps = {
  srcSet: string
  images: {
    path: string
    width: number
    height: number
  }[]
  src: string
  placeholder: string
  width: number
  height: number
  alt?: string
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export const Image = (props: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const paddingTop = `${Math.round((props.height / props.width) * 100)}%`

  return (
    <div
      className={cn(styles.container, props.className)}
      style={{ paddingTop, ...props.style }}
    >
      <span
        className={styles.placeholder}
        style={{
          backgroundImage: `url(${props.placeholder})`,
          opacity: isLoading ? 1 : 0,
        }}
      />
      <img
        className={styles.image}
        src={props.src}
        srcSet={props.srcSet}
        alt={props.alt}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  )
}
