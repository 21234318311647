import { forwardRef } from 'react'

type IconProps = {
  color?: string
  size?: number
  stroke?: number
} & React.SVGProps<SVGSVGElement>

export const CloseIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', size = 16, stroke = 2, ...props }, ref) => (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
      strokeWidth={stroke}
      stroke={color}
      {...props}
    >
      <path d="M 2 2 L 14 14 M 14 2 L 2 14" />
    </svg>
  ),
)
