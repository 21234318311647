import { useElementSize } from 'usehooks-ts'
import { App } from '../../components/app'
import styles from './rsvp.module.css'

export const Rsvp = () => {
  const [ref, size] = useElementSize()

  return (
    <App className={styles.rsvp}>
      <div ref={ref} className={styles.iframeWrapper}>
        <iframe
          className={styles.iframe}
          title="OSA"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdxN-G2JpUf68UVLfkgENCuQqSCsxKKCcrpS0tTvNE_0cztRQ/viewform?embedded=true"
          width="100%"
          height={size.height}
        >
          Loading…
        </iframe>
      </div>
    </App>
  )
}
