import { forwardRef } from 'react'

type IconProps = {
  color?: string
  size?: number
  stroke?: number
} & React.SVGProps<SVGSVGElement>

export const MenuIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor', size = 16, stroke = 2, ...props }, ref) => (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
      strokeWidth={stroke}
      stroke={color}
      {...props}
    >
      <path d="M 0 3 H 16 M 0 8 H 16 M 0 13 H 16" />
    </svg>
  ),
)
