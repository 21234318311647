import { App } from '../../components/app'

export const Info = () => (
  <App>
    <h1>Information</h1>
    <h2>Klädkod</h2>
    <h3>Trädgårdsmingel (torsdag)</h3>
    <p>Ingen klädkod</p>
    <h3>Bröllop (fredag)</h3>
    <p>
      Kavaj. Men vi båda uppskattar alltid en välkomponerad outfit så feel free
      att bortse från klädkoden om ni har något tjusigt att bjuda på.
    </p>
    <h2>Barn</h2>
    <p>
      Barn får gärna vara med på vigseln men på kvällen vill vi fira utan barn.
      Spädbarn är såklart välkomna!
    </p>
    <h2>Gåvor</h2>
    <p>
      Att ni vill dela vår stora dag med oss är gåva nog. Önskar ni ändå att ge
      oss en gåva så hade vi blivit glada för ett bidrag till vår bröllopsresa.
    </p>
    <h2>Önska låt</h2>
    <p>
      Ett röjigt dansgolv är allt vi önskar oss, vad får dig att vilja dansa?
    </p>
    <p>
      <a
        href="https://open.spotify.com/playlist/1WxXsIy5GuafJg0ydUIQav?si=1c8b1bf57703458c"
        target="_blank"
        rel="noreferrer"
      >
        Lägg gärna till låtar här.
      </a>
    </p>
    <iframe
      title="spotify"
      style={{ borderRadius: 12, border: 0 }}
      src="https://open.spotify.com/embed/playlist/1WxXsIy5GuafJg0ydUIQav?utm_source=generator&theme=0"
      width="100%"
      height="152"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    />
    <h2>Fotografera</h2>
    <p>
      Vi kommer att ha en professionell fotograf under vigseln och festen. Vi
      önskar att ni under vigseln är närvarande och fokuserar på stunden och
      istället lämnar fotandet till vår fotograf. Resten av dagen får ni gärna
      fotografera.
    </p>
  </App>
)
