import { App } from '../../components/app'
// import styles from 'program.module.css'

export const Program = () => (
  <App>
    <h1>Program</h1>

    <h2>Trädgårdsmingel</h2>
    <p>
      <b>Torsdag 6 juni från kl 17.00</b>
      <br></br>
      Adress: Gröna Stigen 2, Ängelholm
    </p>
    <p>
      Välkomstmingel i familjen Zetterlunds trädgård. Vi vill gärna välkomna er
      till Ängelholm och Skåne med mingel hos familjen Zetterlund. Vi bjuder på
      lättare mat och dryck.
    </p>
    <p>Kommer ni med bil finns det parkeringsplatser på Strandridarevägen.</p>
    <p>
      Om ni tänkte ta taxi från och till Klitterhus är det billigare att
      promenera bort till Skäldervikens Hamnn så slipper ni åka genom hela
      Ängelholm.
    </p>
    <p>
      Ni kan även ta Stadsbuss 2 från Skälderviken Småbåtshamnen till Björkhagen Kobbvägen.
    </p>
    <h3>
      Tänk på att vi kommer vara utomhus om vädret tillåter så en lite varmare
      jacka och bekvämare skor är en god ide.
    </h3>

    <h2>Vigsel</h2>
    <p>
      <b>Fredag 7 juni 13.15</b>
      <br></br>
      Adress: Järnvägsgatan, Ängelholm
    </p>
    <p>
      Vigseln sker i Ängelholms kyrka fredagen den 7e juni kl. 13.15. Vi önskar
      att ni under vigseln är närvarande i stunden och lämnar fotandet till
      proffset.
    </p>
    <p>
      För er som kommer med bil finns det några parkeringsplatser precis utanför
      men skulle det vara fullt finns ett parkeringsgarage i närheten. Mer
      information om parkering finns{' '}
      <a
        href="https://www.engelholm.se/trafik-och-stadsplanering/parkering/centrala-parkeringsplatser.html"
        target="_blank"
        rel="noreferrer"
      >
        här
      </a>
      . Från Klitterhus går buss 4 till kyrkan.
    </p>

    <h2>Paus</h2>
    <p>
      Mellan vigseln och minglet är det fri lek. Vill ni kanske ta en fika eller
      något att dricka finns det gott om uteserveringar i stan. Det går även bra
      att ta sig till Klitterhus tidigare.
    </p>
    <p>Transport från kyrkan till Klitterhus ansvarar ni själva för:</p>
    <ul>
      <li>
        Det går att ta Stadsbuss 4 mot Havsbaden från Stortorget direkt till
        Klitterhus
      </li>
      <li>
        Det går även att ta Stadsbuss 2 mot Vejbystrand från Stortorget till
        Skälderviken Småbåtshamnen och promenera cirka en kvart
      </li>
      <li>
        Bil går förstås också bra och det finns gott om parkering vid Klitterhus
      </li>
    </ul>

    <h2>Bröllopsfest</h2>
    <p>
      <b>Fredag 7 juni, 16:15</b>
      <br></br>
      Adress: Klittervägen 45, Ängelholm
    </p>
    <p>
      Bröllopet firar vi på Klitterhus med inledande mingel och därefter middag.
      Efter middagen fortsätter festen till sent.
    </p>
    <ul>
      <li>16:15 börjar minglet</li>
      <li>18:00 tänkte vi servera middag</li>
      <li>22:00 invigs dansgolvet så ta med bekväma skor</li>
    </ul>
    <h3>Ta gärna med badkläder och handduk för ett eventuellt nattdopp 🐬</h3>
  </App>
)
