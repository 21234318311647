import { useState, useEffect, useRef } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Link, LinkProps } from 'react-router-dom'
import cn from 'classnames'
import { useLockedBody } from 'usehooks-ts'
import { MenuIcon } from '../icons/menu'
import { CloseIcon } from '../icons/close'
// @ts-ignore
import photo from '../../assets/images/oldschool.jpeg?sizes[]=768'
import styles from './header.module.css'

const links: LinkProps[] = [
  {
    to: '/program',
    children: 'Program',
  },
  {
    to: '/info',
    children: 'Info',
  },
  {
    to: '/toastmasters',
    children: 'Toastmasters',
  },
  {
    to: '/boende',
    children: 'Boende',
  },
  {
    to: '/resa',
    children: 'Resa',
  },
  {
    to: '/osa',
    children: 'OSA',
  },
]

export const Header = () => {
  const [open, setOpen] = useState(false)
  const [sticky, setSticky] = useState(false)
  const ref = useRef<HTMLElement>(null)

  useLockedBody(open, 'root')

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        setSticky(e.intersectionRatio < 1)
      },
      {
        threshold: [1],
        rootMargin: '-1px 0px 0px 0px',
      },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }
  }, [])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen} modal={false}>
      <nav
        ref={ref}
        className={cn(styles.nav, {
          [styles.navMenuOpen]: open,
          [styles.navSticky]: sticky,
        })}
      >
        <ul>
          <li>
            <Link
              to="/"
              className={styles.logo}
              // @ts-ignore
              style={{ '--backgroundImage': `url('${photo.src}')` }}
            >
              M&G
            </Link>
          </li>
          <li>
            <Dialog.Trigger>
              Meny
              {open ? (
                <CloseIcon className={styles.icon} />
              ) : (
                <MenuIcon className={styles.icon} />
              )}
            </Dialog.Trigger>
            {open && (
              <div
                className={cn(styles.overlay, {
                  [styles.overlaySticky]: sticky,
                })}
              >
                <Dialog.Content asChild>
                  <ul className={styles.menu}>
                    {links.map((link, i) => (
                      <li key={i}>
                        <Link {...link} />
                      </li>
                    ))}
                  </ul>
                </Dialog.Content>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </Dialog.Root>
  )
}
