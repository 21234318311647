import { App } from '../../components/app'

export const Travel = () => (
  <App>
    <h1>Resa</h1>
    <p>
      Ängelholm går att nå på många sätt men smidigast (och miljövänligast) är
      tåg. Passar inte tåget går det även att nå Ängelholm med flyg och bil. Har
      ni några funderingar kring resan är ni välkomna att kontakta vår alldeles
      egna resekoordinator, Emelie. Ni når henne via facebook, mail eller
      telefon.
    </p>
    <h3>Emelie Thorburn</h3>
    <p>
      Kontakt
      <br />
      <a href="mailto:emelie.thorburn@gmail.com">emelie.thorburn@gmail.com</a>
      <br />
      <a href="tel:0763364159">0763364159</a>
    </p>
  </App>
)
