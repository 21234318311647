import cn from 'classnames'
import styles from './container.module.css'

type ContainerProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>

export const Container = ({
  children,
  className,
  ...props
}: ContainerProps) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <div {...props} className={styles.container}>
        {children}
      </div>
    </div>
  )
}
