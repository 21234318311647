import { App } from '../../components/app'
import { Image } from '../../components/image'
// @ts-ignore
import anna from '../../assets/images/anna-bw.jpg?sizes[]=768'
// @ts-ignore
import emelie from '../../assets/images/emelie-bw.jpg?sizes[]=768'
import styles from './toastmasters.module.css'

export const Toastmasters = () => (
  <App>
    <h1>Toastmasters</h1>
    <p>
      Anna och Emilie är våra eminenta toastmasters under middagen. Om ni vill
      hålla tal eller annat kul anmäler ni det till dem genom att kontakta Anna
      eller Emilie <b>senast 1 maj</b>.
    </p>
    <div className={styles.toastmasters}>
      <div>
        <Image {...anna} alt="Anna Zetterlund" />
        <h3>Anna Zetterlund</h3>
        <p>
          Kontakt
          <br />
          <a href="mailto:annazetterlund1996@gmail.com">
            annazetterlund1996@gmail.com
          </a>
          <br />
          <a href="tel:0709416775">0709416775</a>
        </p>
      </div>
      <div>
        <Image {...emelie} alt="Emilie Tilstam" />
        <h3>Emilie Tilstam</h3>
        <p>
          Kontakt
          <br />
          <a href="mailto:emilietilstam@gmail.com">emilietilstam@gmail.com</a>
          <br />
          <a href="tel:0732539205">0732539205</a>
        </p>
      </div>
    </div>
  </App>
)
