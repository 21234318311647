import { differenceInDays } from 'date-fns'
import { Container } from '../../components/container'
import { Marquee } from '../../components/marquee'
import { Header } from '../../components/header'
import styles from './app.module.css'

export function App({
  className,
  children,
}: React.HTMLAttributes<HTMLElement>) {
  const daysRemaining = differenceInDays(new Date('2024-06-06'), new Date())
  const marqueeText = `6–7 juni 2024 i Ängelholm • Bara ${daysRemaining} dagar kvar!`

  return (
    <Container className={className}>
      <Marquee>
        <span>{marqueeText}</span>
      </Marquee>
      <Header />
      <main className={styles.main}>{children}</main>
    </Container>
  )
}
