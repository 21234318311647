import { useEffect } from 'react'
import {
  createBrowserRouter,
  Outlet,
  ScrollRestoration,
  RouteObject,
  useLocation,
} from 'react-router-dom'
import ReactGA from 'react-ga'
import { Auth } from '../components/auth'
import { Home } from './home'
import { Info } from './info'
import { Accommodation } from './accommodation'
import { Travel } from './travel'
import { Toastmasters } from './toastmasters'
import { Rsvp } from './rsvp'
import { Program } from './program'

const Root = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  return (
    <>
      <ScrollRestoration />
      <Auth>
        <Outlet />
      </Auth>
    </>
  )
}

const routes: RouteObject[] = [
  {
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/info',
        element: <Info />,
      },
      {
        path: '/boende',
        element: <Accommodation />,
      },
      {
        path: '/resa',
        element: <Travel />,
      },
      {
        path: '/toastmasters',
        element: <Toastmasters />,
      },
      {
        path: '/program',
        element: <Program />,
      },
      {
        path: '/osa',
        element: <Rsvp />,
      },
    ],
  },
]

export const router = createBrowserRouter(routes)
