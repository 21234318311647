import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import ReactGA from 'react-ga'
import 'normalize.css'
import './index.css'
import './fonts.css'
import { router } from './routes'
// import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV !== 'production') {
  ReactGA.initialize('G-RMSH8R3T5L')
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
