import { useState, useCallback, PropsWithChildren, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import styles from './auth.module.css'
import { PASSWORD } from '../../config'

export const Auth = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialPassword = searchParams.get('pw') ?? ''
  const [password, setPassword] = useLocalStorage(
    '__PASSWORD__',
    initialPassword,
  )
  const [error, setError] = useState(false)
  const handleOnSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      const target = ev.target as typeof ev.target & {
        password: { value: string }
      }
      if (target.password.value === PASSWORD) {
        setPassword(target.password.value)
      } else {
        setError(true)
      }
    },
    [setPassword],
  )

  useEffect(() => {
    setSearchParams((prevSearchParams) => {
      prevSearchParams.delete('pw')
      return prevSearchParams
    })
  }, [setSearchParams])

  if (password === PASSWORD) {
    return <>{children}</>
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleOnSubmit} className={styles.form}>
        <label>
          Ange lösenord
          <input type="password" name="password" />
        </label>
        {error && <span>error</span>}
        <button type="submit">Gå vidare</button>
      </form>
    </div>
  )
}
