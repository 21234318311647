import { App } from '../../components/app'
import { Image } from '../../components/image'
// @ts-ignore
import klitterhus from '../../assets/images/klitterhus.jpeg?sizes[]=768'
// import styles from 'accommodation.module.css'

export const Accommodation = () => (
  <App>
    <h1>Boende</h1>
    <h2>Klitterhus</h2>
    <Image {...klitterhus} alt="Klitterhus" />
    <p>
      Det är på Klitterhus bröllopsmiddagen och även fest (och efterfesten) är.
      Det är här vi själva kommer bo och rekommenderar att ni bokar om ni vill
      ha nära att ramla hem på morgonkvisten samt tänkt stanna minst två nätter
      (torsdag-fredag). För att boka dessa smsar ni till Matilda 0709211192 och
      swishar 3800kr till samma nummer. Om ni vill bo kvar lördag natt mailar ni
      lättast till Klitterhus för att boka info@klitterhus.com.
    </p>
    <p>Incheckning är från 12 och utcheckning kl 11.</p>
    <iframe
      title="Google Maps"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2215.806017415954!2d12.833495977076185!3d56.26413147327783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46518087886dba01%3A0xe2c5ec55a8720fe!2sKlitterhus!5e0!3m2!1sen!2sse!4v1699989157719!5m2!1sen!2sse"
      width="700"
      height="450"
      style={{ border: 0, maxWidth: '100%' }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
    <h2>Hus57</h2>
    <p>
      För er som vill få sova i lite lugnare miljö finns även Hus57 i centrala
      Ängelholm. Det ligger 7 minuters bilfärd från Klitterhus. Här har vi
      reserverat både enkel- och dubbelrum åt er. Rummen går att boka 6-9 juni
      2024 genom att maila{' '}
      <a href="mailto:bokning@hus57.se"> bokning@hus57.se</a>. Uppge{' '}
      <strong>Matilda &amp; Gustafs bröllop</strong> och vilka dagar ni önskar
      att boka. Detta gäller fram till 1 maj 2024. Enkelrummen kostar 995kr/natt
      och dubbelrummen 1295kr, efter 1 maj gäller vanliga priser. Mer
      information hittar ni{' '}
      <a href="https://www.hus57.se" target="_blank" rel="noreferrer">
        här
      </a>
      .
    </p>
    <h2>Destination Råbocka</h2>
    <p>
      För den som vill ha nära hem efter bröllopet finns det campingstugor att
      hyra på Destination Råbocka. Dessa ligger 15 minuters promenad från
      Klitterhus.{' '}
      <a href="https://rabocka.se" target="_blank" rel="noreferrer">
        Här kan ni läsa mer och boka
      </a>
      .
    </p>
    <h2>Airbnb</h2>
    <p>
      Det finns även några mysiga Airbnb i området för den som föredrar att bo
      mer avskiljt.{' '}
      <a
        href="https://www.airbnb.com/s/Ängelholm--Sverige/homes?place_id=ChIJ80eXvzN-UUYRB__FCwxitFE&amp;refinement_paths%5B%5D=%2Fhomes&amp;checkin=2024-06-06&amp;checkout=2024-06-08&amp;date_picker_type=calendar&amp;search_type=filter_change&amp;tab_id=home_tab&amp;query=Ängelholm%2C%20Sverige&amp;flexible_trip_lengths%5B%5D=one_week&amp;monthly_start_date=2023-11-01&amp;monthly_length=3&amp;price_filter_input_type=0&amp;price_filter_num_nights=3&amp;channel=EXPLORE&amp;ne_lat=56.336615683787464&amp;ne_lng=12.92694191245667&amp;sw_lat=56.166077668885166&amp;sw_lng=12.713338689654108&amp;zoom=11.47644613275828&amp;zoom_level=11&amp;search_by_map=true&amp;source=structured_search_input_header"
        target="_blank"
        rel="noreferrer"
      >
        Här finns AirBnB
      </a>
      .
    </p>
  </App>
)
